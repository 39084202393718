import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import TeamCommitteePageTemplate from "../../templates/team-committee-page"

const TeamEnglish = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <TeamCommitteePageTemplate {...props} />
    </Layout>
  )
}

export default TeamEnglish

export const pageQuery = graphql`
  query TeamEnglish {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/team/index.en.md/" }
    ) {
      frontmatter {
        ...ContributorsTeamCommitteePages
      }
    }
  }
`
